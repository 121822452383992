import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";

const PostCard = ({ post }) => {
  const url = `/blog/${post.slug}/`;
  const readingTime = "6 min read";

  return (
    <Link to={url} className="post-card">
      <header className="post-card-header">
        {post.heroImage.fluid && (
          <Img
            alt=""
            className="post-card-image"
            fluid={post.heroImage.fluid}
          />
        )}

        {post.featured && <span>Featured</span>}
        <h2 className="post-card-title">{post.title}</h2>
      </header>
      <section
        className="post-card-excerpt"
        dangerouslySetInnerHTML={{
          __html: post.description.childMarkdownRemark.html,
        }}
      />
      <footer className="post-card-footer">
        <div className="post-card-footer-left">
          <div style={{ display: "flex" }}>
            {post.tags &&
              post.tags.map((tag) => (
                <div className="post-card-tags" key={tag}>
                  {tag}{" "}
                </div>
              ))}
          </div>
          {/* <div className="post-card-avatar">
            {post.primary_author.profile_image ? (
              <img
                className="author-profile-image"
                src={post.primary_author.profile_image}
                alt={post.primary_author.name}
              />
            ) : (
              <img
                className="default-avatar"
                src="/images/icons/avatar.svg"
                alt={post.primary_author.name}
              />
            )}
          </div> */}
          {/* <span>{post.primary_author.name}</span> */}
        </div>
        <div className="post-card-footer-right">
          <div>{readingTime}</div>
        </div>
      </footer>
    </Link>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    featured: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    excerpt: PropTypes.string.isRequired,
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PostCard;
